@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.row, .col {
  margin: 0;
padding: 0;
}
.test{
  border: 2px solid red !important;
}
.test1{
  border: 2px solid blue !important;
}
.h-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 40px);
}

.bg-gray{
    background-color: #EBEFF4;
}

.fs_10px{
  font-size: 10px !important;
}

 .btn{
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-width: 1px;   
}

.btn-primary{
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  width: 250px;
}

.btn_bg{
  background-color: #009685 !important;
    color: #fff !important;
}

.btn-dark:hover{
  background-color: #2A2A38;
  border-color: #2A2A38;
}

.btn-blue{
  background-color: #1B51A4;
  border-color: #1B51A4;
  color: #fff;
  width: 250px;
}

.btn-outline{
  background-color: transparent;
  border-color: #2A2A38;
  color: #2A2A38;
  width: 250px;
}

.btn-outline:hover{
  border-color: #2A2A38;
  color: #2A2A38;
}
.nav-gradient{
  background: linear-gradient(to right, #070707, #242264);
}
.form-control{
  width: 100% !important;
  border: 1px solid #242264;
}
.container{
  max-width: 1200px !important;
}
.home-d1:hover .home-d1-inner1, .home-d2:hover .home-d2-inner2, .home-d3:hover .home-d3-inner3, .home-d4:hover .home-d4-inner4 {
background-color: #1B51A4!important;
color: white !important;
}

.pkg-hov:hover{
  background: linear-gradient(to bottom, #070707, #242264);
}
.pkg-hov:hover .txt-dark{
color: white !important;
}
.white_space{
  white-space: nowrap !important;
}

.css-1d1gch6-MuiListItem-root.active .myIconClass, .css-1d1gch6-MuiListItem-root.active .MuiTypography-root{
  color: black!important;
}

/* ul .MuiListItem-gutters.active .MuiListItemText-root p{
  color: black !important;
 
  background-color: white;
  padding: 8px;

}

ul .MuiListItem-gutters.active .myIconClass{
   color: black !important;
   background-color: white;
   padding: 8px;
} */



.MuiTypography-root .MuiTypography-body2 .css-e784if-MuiTypography-root{

  color: #007bff !important;
}
.active .MuiTypography-root{
  color: black !important;
}
.custom-text{
  font-size: 16px !important;
  font-weight: bold !important;
  text-wrap: wrap !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  /* Set your desired color for the checked checkbox */
  color: rgb(0,160,152) !important; /* Change to your preferred color */
  /*color: #fff !important;  Change to your preferred text color */
}
.scroll::-webkit-scrollbar{
  width: 15px !important;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 15px !important;
  background: linear-gradient(to top, rgb(106, 212, 201) , rgb(186, 186, 186) ) !important;
}
.scroll::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, rgb(0,160,152) 50%, black  ) !important;
  border-radius: 10px;
}
.scroll-table::-webkit-scrollbar{
  height: 10px !important;
}
.scroll-table::-webkit-scrollbar-track {
  background: linear-gradient(to right, rgb(106, 212, 201) , rgb(186, 186, 186) ) !important;
  /* box-shadow: inset 0 0 3px grey;  */
  border-radius: 10px !important;

}
.scroll-table::-webkit-scrollbar-thumb {
  background: linear-gradient(to left, rgb(0,160,152) 50%, black) !important;
  border-radius: 10px;
}

.btn-overdue {
  position: relative;
  display: inline-block;
  color: #fff;
  padding: 6px !important;
  background: linear-gradient(to bottom, #ff7272, #FF5252);
  text-decoration: none;
  width: 100%;
}

.btn-inner-overdue {
  display: block;
  color: #fff;
  background: linear-gradient(to top, #ff7272, #FF5252);
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}
.btn-complete {
  position: relative;
  display: inline-block;
  color: #fff;
  padding: 6px !important;
  background: linear-gradient(to bottom, #75b45e, #2FAF01);
  text-decoration: none;
  width: 100%;
}

.btn-inner-complete {
  display: block;
  color: #fff;
  background: linear-gradient(to top, #75b45e, #2FAF01);
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}
.btn-pending {
  position: relative;
  display: inline-block;
  color: #fff;
  padding: 6px !important;
  background: linear-gradient(to bottom, #fed56d, #FEB804);
  text-decoration: none;
  width: 100%;
}

.btn-inner-pending {
  display: block;
  color: #fff;
  background: linear-gradient(to top, #fed56d, #FEB804);
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}
.edit-icon{
  display: none;
}
 .edit-profile:hover .edit-icon{
  display: block !important;
}

.h1Color{
  color: #1ba8a0;
}

.pointer{
  cursor: pointer;
}

.customBtn{
  background-color: #1ba8a0;
  border: none;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  transition: 0.3s all ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customBtn:hover{
  background-color: rgb(15, 144, 137);
}

.customBtnColor{
  background-color: rgb(0,160,152) !important;
  color: white !important;
}
#forDownload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* @media print {
  .border {
      border: 1px solid black;
  }
  .d-none {
      display: none;
  }
  .d-block {
      display: block;
  }
} */


/* Sidebar custom start */
/* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper > div {
  padding: 0 !important;
  height: 100dvh;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper .mx-auto {
  height: 150px;
}

.MuiDrawer-paperAnchorDockedLeft .MuiList-padding {
  height: calc(100dvh - 220px);
  overflow-y: auto;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper .translate-middle {
  height: 40px;
  overflow: hidden
}

.MuiDrawer-paperAnchorDockedLeft .MuiList-padding::-webkit-scrollbar{
  width: 5px !important;
}
.MuiDrawer-paperAnchorDockedLeft .MuiList-padding::-webkit-scrollbar-track {
  border-radius: 15px !important;
  background: linear-gradient(to top, rgb(106, 212, 201) , rgb(186, 186, 186) ) !important;
}
.MuiDrawer-paperAnchorDockedLeft .MuiList-padding::-webkit-scrollbar-thumb {
  background-color: rgb(77,77,77) !important;
  border-radius: 10px;
} */
/* Sidebar custom end */

.button-normal-case {
  text-transform: none !important;
}
